import { makeAutoObservable, runInAction } from "mobx";

import { TFAStatusType } from "./model";

import { TFAApi } from "shared/api/TwoFactorAuthentication";

const { getTfaStatus } = TFAApi();

export class TFAStore {
  tfa: TFAStatusType | null = null;
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadStatus = async () => {
    if (!this.tfa) {
      this.loading = true;
      const result = await getTfaStatus("1");
      if (result) {
        runInAction(() => {
          this.tfa = result[0].result.status;
          this.loading = false;
        });
      }
    }
  };

  updateTFA = (val: TFAStatusType) => {
    this.tfa = val;
  };
}
