import { request } from "../request";
import { setupData } from "../utils";

export const SettingsApi = () => {
  /** Акционный фон для ЛКВ */
  const dataBackgroundConfig = setupData("profile.get-background");

  const getBackground = async (id: string) => {
    return await request([{ ...dataBackgroundConfig, id: id }]);
  };

  return {
    dataBackgroundConfig,
    getBackground,
  };
};
