import { makeAutoObservable, runInAction, computed } from "mobx";
import uniqid from "uniqid";

import { userMocks } from "./mock";
import { UserProps, SystemType, ContactsProps, BalanceProps } from "./model";

import { UserApi } from "shared/api";
import { JsonrpcResponse } from "shared/api/request";
import { NewPassword } from "shared/api/User/types";
import { NotificationType } from "shared/ui/Notification";
import { getCurrencySymbol } from "shared/utils";

const { getUser, updateUser, updateUserPassword, logout } = UserApi();

export class UserStore {
  user: UserProps | undefined = undefined;
  contacts: ContactsProps | undefined = undefined;
  balance: BalanceProps | undefined = undefined;
  activeTickets: number | undefined = undefined;
  paymentsCount: number | undefined = undefined;

  loading = true;

  constructor() {
    makeAutoObservable(this);
  }

  load = async () => {
    const requestId = uniqid();
    const result: any = await getUser(requestId);
    runInAction(() => {
      if (result) {
        this.user = { ...result[0].result, system: 1 };
        this.loading = false;
      } else {
        this.user = userMocks;
        this.loading = false;
      }
    });
  };

  loadMock = (val: UserProps) => {
    this.user = val;
  };

  get userId(): number | null {
    return this.user?.id || null;
  }

  get userLogin(): string {
    return this.user?.login || "-";
  }

  get userSystem(): SystemType | undefined {
    return this.user?.system || undefined;
  }

  get userFullName(): string {
    if (this.user) {
      const name =
        this.user.firstName || this.user.lastName ? this.user.firstName + " " + this.user.lastName : this.user.login;
      return `(${this.userId}) ${name.trim()}`;
    }
    return "";
  }

  get userCurrencySymbol(): string {
    return getCurrencySymbol(this.balance?.currencyCode || "RUB");
  }

  get userTotalBalance(): string {
    return `${this.balance?.totalBalance} ${this.userCurrencySymbol}`;
  }

  get userAvailableBalance(): string {
    return `${this.balance?.availableBalance} ${this.userCurrencySymbol}`;
  }

  get userBlockedBalance(): string {
    return `${this.balance?.blockedBalance} ${this.userCurrencySymbol}`;
  }

  get userHold(): string {
    return `${this.balance?.hold} ${this.userCurrencySymbol}`;
  }

  editUser = async (val: UserProps) => {
    const requestId = uniqid();
    const result: void | JsonrpcResponse[] = await updateUser(requestId, val);
    if (result && result[0].result) {
      this.user = val;
      return { status: "success" as NotificationType };
    }
    if (result && result[0].error) {
      if (typeof result[0].error.data === "object") {
        return { status: "error" as NotificationType, fields: { ...result[0].error.data } };
      }
    }
    this.user = val;
  };

  editUserPassword = async (val: NewPassword) => {
    const requestId = uniqid();
    const result: void | JsonrpcResponse[] = await updateUserPassword(requestId, val);

    if (result && result[0].result) {
      return { status: "success" as NotificationType };
    }
    if (result && result[0].error) {
      if (typeof result[0].error.data === "object") {
        return { status: "error" as NotificationType, fields: { ...result[0].error.data } };
      }
    }
  };

  updateContacts = (val: ContactsProps) => {
    this.contacts = val;
  };

  updateBalance = (val: BalanceProps) => {
    this.balance = val;
  };

  updateUser = (user: UserProps) => {
    this.user = user;
    this.loading = false;
  };

  updatePassword = async (data: NewPassword) => {
    // const result: Response | void = await editPassword(data);
    return {
      status: "success",
    };
  };

  updateActiveTickets = (value: number) => {
    this.activeTickets = value;
  };

  updatePaymentsCount = (value: number) => {
    this.paymentsCount = value;
  };

  generateAuthToken = () => {
    if (this.user) {
      this.user.accessToken = uniqid();
    }
  };

  deleteAuthToken = () => {
    if (this.user) {
      this.user.accessToken = "";
    }
  };

  logout = async (id: string) => {
    return await logout(id);
  };
}
