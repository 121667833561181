import { makeAutoObservable } from "mobx";

import { NotificationProps } from "./model";

import { NotificationApi } from "shared/api";

const { getNotifications, readNotification, readAllNotifications } = NotificationApi();

export class NotificationPreviewStore {
  previewNotifications: NotificationProps[] = [];
  count = 0;
  loading = false;

  constructor() {
    makeAutoObservable(this);
    this.loadPreviewNotifications();
  }

  loadPreviewNotifications = async () => {
    this.loading = true;
    const result = await getNotifications("1", { isRead: false, perPage: 4, page: 1 });

    if (Array.isArray(result.result) && result.result[0]) {
      this.update(result.result[0].result.messages ?? []);
    }
    this.loading = false;
  };

  update = (val: NotificationProps[]) => {
    if (Array.isArray(val)) {
      this.previewNotifications = [...val];
    }
  };

  updateCount = (val: number) => {
    this.count = val;
  };

  read = async (ids: number[]) => {
    const result = await readNotification("1", { ids });
    if (result.result) {
      this.loadPreviewNotifications();
    }
  };

  readAll = async () => {
    const result = await readAllNotifications("1");
    if (result.result) {
      this.loadPreviewNotifications();
    }
  };
}
