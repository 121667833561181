import axios, { AxiosResponse } from "axios";

// docker frontend url https://web.fork.localhost:8443/
const BASE_URL: string = process.env.REACT_APP_AUTH_URL ?? "";
const UNAUTH_URL: string = process.env.REACT_APP_PUBLIC_URL ?? "";

interface ResponseDataProps extends Omit<AxiosResponse, "data"> {
  data: JsonrpcResponse[];
}

export interface JsonrpcRequest {
  jsonrpc: string;
  id: string;
  method: string;
  params?: Record<string, any>;
}

export interface JsonrpcResponse {
  jsonrpc?: string;
  id: string;
  result?: any;
  error?: {
    code: number;
    data?: Record<string, string> | string;
    message: string;
  };
}

export const axiosInstance = axios.create({
  withCredentials: true,
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
  },
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && Array.isArray(error.response.data)) {
      const res = error.response.data.some((req: any) => req.result);
      if (res) {
        return error.response;
      }

      const unAuth = error.response.data.some((req: any) => req.error.code === 403);
      if (unAuth) {
        document.location.replace(UNAUTH_URL);
      }
    }

    return Promise.reject(error);
  },
);

export const request = async (data: JsonrpcRequest[]) => {
  const result = await axiosInstance
    .post(BASE_URL, data)
    .then((response: ResponseDataProps) => {
      return response.data;
    })
    .catch((error) => console.log(error));

  return result;
};
