import { FC } from "react";

import { Grid, Col } from "shared/ui/Grid";
import { Skeleton as SkeletonComponent } from "shared/ui/Skeleton";

const renderSkeletons = (count: number) => {
  for (let i = 0; i < count; i++) {
    return (
      <div style={{ padding: "18px 16px", borderBottom: "1px solid #E9E9E9" }}>
        <SkeletonComponent columns={5} rows={1} />
        <SkeletonComponent columns={4} rows={2} />
        <SkeletonComponent columns={12} rows={4} />
      </div>
    );
  }
};

export const Skeleton: FC<{ count: number }> = ({ count }) => {
  return (
    <Grid>
      <Col span={12}>{renderSkeletons(count)}</Col>
    </Grid>
  );
};
