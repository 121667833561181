import { FC, useEffect, useRef, useState } from "react";

import { ReactComponent as NotificationsIcon } from "@material-symbols/svg-400/rounded/notifications.svg";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import { useOnClickOutside } from "usehooks-ts";

import { useStore } from "entities/context";

import classes from "./Notifications.module.scss";
import { Skeleton } from "./skeleton";

import { INotifications } from "widgets/Header/types";
import { NotificationCard } from "widgets/NotificationCard";

import { BaseButton } from "shared/ui/BaseButton";
import { Button } from "shared/ui/Button";
import { Heading } from "shared/ui/Heading";
import { Link } from "shared/ui/Link";
import { Text } from "shared/ui/Text";

export const Notifications: FC<INotifications> = observer(({ open, handleOpen }) => {
  const {
    notificationStore: { previewNotifications, loading, count, loadPreviewNotifications, readAll },
  } = useStore();
  const ref = useRef(null);

  useEffect(() => {
    if (!previewNotifications) {
      loadPreviewNotifications();
    }
  }, [open]);

  useOnClickOutside(ref, () => handleOpen(false));

  return (
    <div ref={ref} className={classes.container}>
      <BaseButton onClick={handleOpen} className={cn(classes.bell, open && classes.open)}>
        <NotificationsIcon className={classes["bell-icon"]} />
        {count > 0 && <span className={classes.label} />}
      </BaseButton>
      {open && (
        <div data-testid="header-notifications-content" className={classes.content}>
          <div className={classes.header}>
            <div className={classes.title}>
              {count !== 0 && <span className={classes.count}>{count}</span>}
              <Heading tag="h3" weight="semi-bold">
                Уведомления
              </Heading>
            </div>
            <Button disabled={count === 0} onClick={() => readAll()} theme="secondary" size="small">
              Прочитать все
            </Button>
          </div>
          {!loading ? (
            count > 0 ? (
              previewNotifications.map((notification) => (
                <div key={"notify-preview-" + notification.id} className={classes.card}>
                  <NotificationCard preview notification={notification} />
                </div>
              ))
            ) : (
              <Text size="lg" height="big" className={classes.empty}>
                У вас нет непрочитанных уведомлений
              </Text>
            )
          ) : (
            <Skeleton count={count} />
          )}
          <div className={classes.footer}>
            <NotificationsIcon className={classes.icon} />
            <a className={classes.link} href={`${process.env.REACT_APP_ACCOUNT_URL}/profile/notifications`}>
              Перейти ко всем уведомлениям
            </a>
          </div>
        </div>
      )}
    </div>
  );
});
