import { request } from "../request";
import { setupData } from "../utils";

export const NotificationApi = () => {
  const dataNotificationConfig = setupData("profile.get-notification-all");

  const getNotifications = async (id: string, params: any) => {
    const result = await request([{ ...dataNotificationConfig, id: id, params: params }]);
    return { result: result, data: dataNotificationConfig };
  };

  const dataNotificationCountConfig = setupData("profile.get-notification-count");

  const getNotificationCount = async (id: string) => {
    const result = await request([{ ...dataNotificationCountConfig, id: id }]);
    return { result: result, data: dataNotificationCountConfig };
  };

  const readAllNotificationsConfig = setupData("profile.notification-mark-read-all");

  const readAllNotifications = async (id: string) => {
    const result = await request([{ ...readAllNotificationsConfig, id: id }]);
    return { result: result, data: readAllNotificationsConfig };
  };

  const readNotificationConfig = setupData("profile.notification-mark-read");

  const readNotification = async (id: string, params: Record<"ids", number[]>) => {
    const result = await request([{ ...readNotificationConfig, id: id, params: params }]);
    return { result: result, data: readNotificationConfig };
  };

  return {
    dataNotificationConfig,
    dataNotificationCountConfig,
    readAllNotificationsConfig,
    readNotificationConfig,
    getNotifications,
    getNotificationCount,
    readAllNotifications,
    readNotification,
  };
};
